import React from 'react';
import { Link } from 'gatsby';
import GridContainer from '../layout/Grid/GridContainer.js';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import Section from '../layout/Section.js';
import SEO from '../components/SEO.js';

export default function FourOhFourPage() {
  return (
    <>
      <SEO
        title="404"
        description="Die aufgerufene Seite konnte leider nicht gefunden werden."
      />

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn>
              <h2>
                Die aufgerufene Seite konnte leider nicht gefunden werden.
              </h2>
              <Link to="./">Zur Startseite</Link>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}
